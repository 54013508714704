<template>
  <div class="pay-panel">
    <div class="content">
      <div class="content-top-bg" style="background-color: rgb(7, 193, 96);"></div>
      <div class="tips">
        <div class="tips-title" style="color: rgb(7, 193, 96);">
          欢迎使用聚合收银台
        </div>
        <div class="tips-image">
          <img src="@/assets/images/sm.svg" alt="" />
        </div>
        <div class="tips-content">
          <p>请使用手机</p>
          <p>扫描聚合收款码进入</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
  @import './pay.css';
</style>
